<template>
    <el-aside width="200px">
        <el-menu
                :router="true"
                class="el-menu-vertical-demo"
                background-color="#545c64"
                text-color="#fff"
                active-text-color="#409EFF" style="height: 100%;">

            <template v-for="(menu,index) in submenus">
                <el-submenu v-if="menu.children.length>0" :index="''+menu.id">
                    <template slot="title">{{menu.name}}</template>
                    <el-menu-item :key="leafindex" :index="leafmenu.routePath" :route="{name: leafmenu.routeName}" v-for="(leafmenu,leafindex) in menu.children">
                        <span slot="title">{{leafmenu.name}}</span>
                    </el-menu-item>
                </el-submenu>


                <el-menu-item v-else :index="menu.routePath" :route="{name: menu.routeName}">
                    <span slot="title">{{menu.name}}</span>
                </el-menu-item>
            </template>

<!--            <el-menu-item :index="''+menu.id" :route="{name: menu.path}" :key="index" v-for="(menu,index) in submenus" >-->
<!--                <span slot="title">{{menu.name}}</span>-->
<!--            </el-menu-item>-->
            <!--          <el-menu-item index="1" :route="{name: 'EquipList.Batch'}">-->
            <!--            <span slot="title">设备列表</span>-->
            <!--          </el-menu-item>-->
        </el-menu>
    </el-aside>
</template>

<script>

    export default {
        name: "Aside",
        computed:{
            submenus:{
                get() {
                    return this.$store.state.menu.asideMenus;
                }
            }
        },
        updated() {
            document.querySelector('aside .el-menu-item').click();
        }
    }
</script>

<style scoped>

</style>